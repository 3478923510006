

















































































import Vue from "vue";
import { Offer } from "@/store/types";

export default Vue.extend({
  name: "EditOffer",

  props: { editOffer: { type: Object, default: null } },

  data() {
    return {
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      offer: {
        id: "",
        category: {
          name: ""
        },
        link: "",
        sku: "",
        name: "",
        active: false,
        price: 0,
        priceFrom: 0,
        store: {
          name: "",
          thumbnail: ""
        },
        thumbnail: "",
        featured: false,
        daily: false,
        notified_on_telegram: false,
        notified_on_messaging: false,
        notified_on_whatsapp: false,
        locked: false,
        updatedAt: new Date(),
        expiresIn: ""
      } as Offer
    };
  },

  mounted() {
    if (this.editOffer) {
      Object.assign(this.offer, this.editOffer);
    }
  },

  methods: {
    saveOffer(): void {
      if (!this.validateForm()) return;

      if (this.editOffer) {
        this.$store.dispatch("offer/updateOffer", this.offer);
      } else {
        this.$store.dispatch("offer/createOffer", this.offer);
      }

      this.$emit("close");
    },

    validateForm(): Boolean {
      if (!this.offer.name) {
        this.showError("Insira um nome para a oferta!");
        return false;
      }
      if (!this.offer.sku) {
        this.showError("Insira um nome para a oferta!");
        return false;
      }
      if (!this.isValidUrl(this.offer.link)) {
        this.showError("Insira o link da oferta!");
        return false;
      }
      if (!this.isValidUrl(this.offer.thumbnail)) {
        this.showError("Insira o link da imagem da oferta!");
        return false;
      }
      if (!this?.offer?.category?.name) {
        this.showError("Insira o nome da categoria!");
        return false;
      }
      if (!this.offer.store.name) {
        this.showError("insira o nome da loja!");
        return false;
      }
      if (this.isFloatNumber(this.offer.price)) {
        this.showError("Preço atual não é um número válido!");
        return false;
      }
      if (this.isFloatNumber(this.offer.priceFrom)) {
        this.offer.priceFrom = 0;
      }
      return true;
    },

    isFloatNumber(value: any): Boolean {
      return isNaN(parseFloat(value));
    },

    isValidUrl(string: string): boolean {
      try {
        new URL(string);
      } catch (_) {
        return false;
      }

      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    }
  }
});
